/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    ul, ol {
        @apply ml-5;
    }
    ul {
        @apply list-disc;
    }

    a {
        @apply text-primary-500;
    }

    /* TODO: adminのstyleと共通にする */
    #project_content h2 {
        @apply my-6;
        @apply text-[1.1375rem];
        @apply font-bold;
    }
    #project_content h3 {
        @apply my-4;
        @apply text-lg;
        @apply font-bold;
    }
    #project_content a {
        @apply text-primary-500;
    }
    #project_content p {
        @apply leading-7;
    }

}

html {
    height: -webkit-fill-available;
}

body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;

    @apply text-gray-700;
}

.page-loader {
    border-top-color: #008272;
}

/* froala quick action buttons */
.fr-quick-insert a.fr-floating-btn svg.fr-svg {
    height: 40px !important;
}

.fr-qi-helper a.fr-btn.fr-floating-btn {
    padding: 10px !important;
}
